import { GlobalStyles, CssBaseline, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        {children}
        <Footer />
      </Box>
    </>
  );
};
