import { Box, Drawer, List, ListItemButton } from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { Dispatch, SetStateAction } from "react";

export const LeftSidebar = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const data = useStaticQuery<GatsbyTypes.LeftSidebarQuery>(graphql`
    query LeftSidebar {
      allSanityService {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `);
  const {
    allSanityService: { edges },
  } = data;
  const { open, setOpen } = props;
  const StyledLink = styled(Link)<LinkProps>(({ theme }) => ({
    textDecorationLine: "none",
    color: theme.palette.primary.main,
    flex: 1,
    padding: "4px 16px",
  }));

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={{ width: 250 }}>
        <List>
          {edges.map(({ node: { id, name, slug } }) => (
            <ListItemButton key={id} component="li" sx={{ p: 0 }}>
              <StyledLink
                to={`/${slug}`}
                activeStyle={{ opacity: 0.5, color: "inherit" }}
              >
                {name}
              </StyledLink>
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
