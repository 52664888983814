import React from "react";
import {
  Container,
  Typography,
  Box,
  Link,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { graphql, navigate, useStaticQuery } from "gatsby";

export const Footer = () => {
  const data = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
    query Footer {
      site: sanitySiteSettings {
        title
        author
      }
    }
  `);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: "auto",
        py: [3, 4],
      }}
    >
      <Container maxWidth="md">
        <Stack
          direction={isMobile ? "column" : "row-reverse"}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Stack direction="row" spacing={2}>
            {[
              { href: "sitepolicy", label: "サイトポリシー" },
              { href: "privacy", label: "プライバシーポリシー" },
            ].map(({ href, label }) => (
              <Link
                variant="body2"
                color="text.secondary"
                href={`/${href}`}
                underline="none"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/${href}`);
                }}
              >
                {label}
              </Link>
            ))}
          </Stack>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {`© ${new Date().getFullYear()} ${data.site.title}`}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
