import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

interface ISeoProps {
  title: string;
  description: string;
}

export const Seo = ({ title, description }: ISeoProps) => {
  const { site } = useStaticQuery<GatsbyTypes.SiteSettingsQuery>(graphql`
    query SiteSettings {
      site: sanitySiteSettings {
        title
        author
        description
      }
    }
  `);
  const metaDescription = description || site.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: "ja-jp",
      }}
      title={title}
      titleTemplate={title === site.title ? "%s" : `%s | ${site.title}`}
      link={[
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png",
        },
        {
          rel: "manifest",
          href: "/site.webmanifest",
        },
      ]}
      meta={[
        { name: "description", content: metaDescription },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: site.author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ]}
    />
  );
};
