import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  styled,
  LinkProps,
} from "@mui/material";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { LeftSidebar } from "./LeftSidebar";
import { Link } from "gatsby";

export const Header = () => {
  const data = useStaticQuery<GatsbyTypes.HeaderQuery>(
    graphql`
      query Header {
        site: sanitySiteSettings {
          title
        }
      }
    `
  );
  const [open, setOpen] = useState(false);
  const GatsbyLink = styled(Link)<LinkProps>(({ theme }) => ({
    textDecorationLine: "none",
    color: "inherit",
  }));

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={0}>
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography
            component="p"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <GatsbyLink to="/">{data.site.title}</GatsbyLink>
          </Typography>
          <nav>
            <Button
              component="a"
              href="/blog"
              sx={{ p: 0, mr: 1, minWidth: "auto" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/blog");
              }}
            >
              ブログ
            </Button>
            <Button
              sx={{ p: 0 }}
              onClick={() => {
                setOpen(true);
              }}
            >
              スクール一覧
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
      <LeftSidebar open={open} setOpen={setOpen} />
    </>
  );
};
